const ClientData = [
    {
        id: 1,
        cover: require("../../Assets/PresClients/c1.jpg")
    },
    {
        id: 3,
        cover: require("../../Assets/PresClients/c3.png")
    },
    {
        id: 4,
        cover: require("../../Assets/PresClients/c4.png")
    },
    {
        id: 5,
        cover: require("../../Assets/PresClients/c5.jpg")
    },
    {
        id: 6,
        cover: require("../../Assets/PresClients/c6.png")
    },
    {
        id: 7,
        cover: require("../../Assets/PresClients/c7.png")
    },
    {
        id: 8,
        cover: require("../../Assets/PresClients/c8.png")
    },
    {
        id: 9,
        cover: require("../../Assets/PresClients/c9.png")
    },
    {
        id: 10,
        cover: require("../../Assets/PresClients/c10.png")
    },
    {
        id: 11,
        cover: require("../../Assets/PresClients/c11.png")
    },
    {
        id: 12,
        cover: require("../../Assets/PresClients/c12.jpg")
    },
    {
        id: 13,
        cover: require("../../Assets/PresClients/c13.jpg")
    },
    {
        id: 14,
        cover: require("../../Assets/PresClients/c14.jpg")
    },
    {
        id: 15,
        cover: require("../../Assets/PresClients/c15.jpg")
    },
    {
        id: 16,
        cover: require("../../Assets/PresClients/c16.png")
    },
    {
        id: 17,
        cover: require("../../Assets/PresClients/c17.png")
    },
    {
        id: 18,
        cover: require("../../Assets/PresClients/c18.png")
    },
    {
        id: 19,
        cover: require("../../Assets/PresClients/c19.webp")
    },
    {
        id: 20,
        cover: require("../../Assets/PresClients/c20.png")
    },
    {
        id: 21,
        cover: require("../../Assets/PresClients/c21.webp")
    },
    {
        id: 22,
        cover: require("../../Assets/PresClients/c22.png")
    },
    {
        id: 23,
        cover: require("../../Assets/PresClients/c23.png")
    },
    {
        id: 24,
        cover: require("../../Assets/PresClients/c24.webp")
    },
    {
        id: 25,
        cover: require("../../Assets/PresClients/c25.jpg")
    },
    {
        id: 26,
        cover: require("../../Assets/PresClients/c26.png")
    },
    {
        id: 27,
        cover: require("../../Assets/PresClients/c27.png")
    },
]

export default ClientData;