const GalleryData = [
  {
    id: 1,
    cover: require("../../Assets/Gallery/image-1.jpg"),
    alttag: "",
  },
  {
    id: 2,
    cover: require("../../Assets/Gallery/image-2.jpg"),
    alttag: "",
  },
  {
    id: 3,
    cover: require("../../Assets/Gallery/image-3.jpg"),
    alttag: "",
  },
  {
    id: 4,
    cover: require("../../Assets/Gallery/image-4.jpg"),
    alttag: "",
  },
  {
    id: 5,
    cover: require("../../Assets/Gallery/image-5.jpg"),
    alttag: "",
  },
  {
    id: 6,
    cover: require("../../Assets/Gallery/image-6.jpg"),
    alttag: "",
  },
  {
    id: 7,
    cover: require("../../Assets/Gallery/image-7.jpg"),
    alttag: "",
  },
  {
    id: 8,
    cover: require("../../Assets/Gallery/image-9.jpg"),
    alttag: "",
  },
  {
    id: 9,
    cover: require("../../Assets/Gallery/image-10.jpg"),
    alttag: "",
  },
  {
    id: 10,
    cover: require("../../Assets/Gallery/image-11.jpg"),
    alttag: "",
  },
  {
    id: 11,
    cover: require("../../Assets/Gallery/image-12.jpg"),
    alttag: "",
  },
  {
    id: 12,
    cover: require("../../Assets/Gallery/image-13.jpg"),
    alttag: "",
  },
  {
    id: 13,
    cover: require("../../Assets/Gallery/image-14.jpg"),
    alttag: "",
  },
  {
    id: 14,
    cover: require("../../Assets/Gallery/image-15.jpg"),
    alttag: "",
  },
]

export default GalleryData;